// @ts-nocheck
// eslint-disable
// ---------------------------------------------------
// 🚗🚦 Generated by nuxt-typed-router. Do not modify !
// ---------------------------------------------------

import {useRouter as defaultRouter} from '#imports';
import type {TypedRouter} from './__router';

/**
 * Typed clone of `useRouter`
 *
 * @exemple
 *
 * ```ts
 * const router = useRouter();
 * ```
 */
export function useRouter(): TypedRouter {
    const router = defaultRouter();

    return router;
}
